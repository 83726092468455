import { __awaiter, __generator } from "tslib";
// @ts-nocheck
/* eslint-disable no-console */
import { applyPolyfills, defineCustomElements } from '@eng-ds/agid-web-components/loader';
import { registerApplication, start } from 'single-spa';
import './styles';
function loadDependency(packageName) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                return [2 /*return*/, System["import"](packageName)];
            }
            catch (error) {
                console.error("Package \"" + packageName + "\" could not be loaded.", error);
            }
            return [2 /*return*/];
        });
    });
}
function registerAuth() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            registerApplication({
                name: 'adsp-auth',
                app: System["import"]('adsp-auth'),
                activeWhen: function () { return true; }
            });
            return [2 /*return*/];
        });
    });
}
function registerApplications() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            registerApplication({
                name: 'adsp-header',
                app: function () { return System["import"]('adsp-header'); },
                activeWhen: function () { return true; }
            });
            registerApplication({
                name: 'adsp-footer',
                app: function () { return System["import"]('adsp-footer'); },
                activeWhen: function () { return true; }
            });
            registerApplication({
                name: 'adsp-changelog',
                app: function () { return System["import"]('adsp-changelog'); },
                activeWhen: function (location) { return location.pathname.startsWith('/changelog'); }
            });
            registerApplication({
                name: 'adsp-homepage',
                app: function () { return System["import"]('adsp-homepage'); },
                activeWhen: function (location) {
                    return location.pathname.startsWith('/manutenzione') ||
                        location.pathname.startsWith('/procedimenti') ||
                        location.pathname.startsWith('/rubrica') ||
                        location.pathname === '/';
                }
            });
            registerApplication({
                name: 'adsp-cip',
                app: function () { return System["import"]('adsp-cip'); },
                activeWhen: function (location) { return location.pathname.startsWith('/cip'); }
            });
            registerApplication({
                name: 'adsp-rua',
                app: function () { return System["import"]('adsp-rua'); },
                activeWhen: function (location) { return location.pathname.startsWith('/rua'); }
            });
            registerApplication({
                name: 'adsp-multilingua',
                app: function () { return System["import"]('adsp-multilingua'); },
                activeWhen: function (location) { return location.pathname.startsWith('/multilingua'); }
            });
            registerApplication({
                name: 'adsp-mgu',
                app: function () { return System["import"]('adsp-mgu'); },
                activeWhen: function (location) { return location.pathname.startsWith('/amm'); }
            });
            registerApplication({
                name: 'adsp-scrivania',
                app: function () { return System["import"]('adsp-scrivania'); },
                activeWhen: function (location) { return location.pathname.startsWith('/sua'); }
            });
            registerApplication({
                name: 'adsp-bpm',
                app: function () { return System["import"]('adsp-bpm'); },
                activeWhen: function (location) { return location.pathname.startsWith('/bpm'); }
            });
            return [2 /*return*/];
        });
    });
}
function loadDependencies() {
    loadDependency('rxjs')
        .then(function () {
        return loadDependency('rxjs/operators');
    })
        .then(function () {
        return import('./js');
    })
        .then(function () {
        return registerApplications();
    })
        .then(function () {
        return registerAuth();
    })
        .then(function () {
        setTimeout(function () {
            document.querySelector('.spinner').style.setProperty('display', 'none');
        }, 1000);
    });
}
function bootstrap() {
    applyPolyfills().then(function () {
        defineCustomElements();
    });
    loadDependencies();
    // onBeforeRouting();
    start();
}
function onBeforeRouting() {
    var loadingRoutes = [
        '/admin/cip',
        '/scrivania',
        '/bpm',
        '/admin/multilingua',
        '/admin/mgu',
        '/',
        '/manutenzione',
        '/procedimenti',
        '/rubrica'
    ];
    window.addEventListener('single-spa:before-routing-event', function (evt) {
        var newUrl = evt.detail.newUrl;
        if (loadingRoutes.find(function (path) { return newUrl === "" + process.env.HOST_APP + path; })) {
            Adsp.loading.show();
        }
    });
}
bootstrap();
